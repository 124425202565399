import React, { useState } from 'react';
import './ExamCenter.css';

const list = [
  {
    title: 'Class V B Hindi',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScuz64PpZlsyH6Qjw1ySXFy1NEUuzb8sUXKsFkYLG2ImkkexQ/viewform?embedded=true" width="640" height="3537" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class V B Bengali',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfFnvB30x3DmatIAyQK_ziedSjKzq_El76sxkajhMilpvuIhw/viewform?embedded=true" width="640" height="2979" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class IV B Bengali',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdnQ7995tZ9C66D-L5fIgzNkXA77RuYMk4LK0HLeNaKVN-y0A/viewform?embedded=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class IV B Hindi',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdMZB0Dm8QokwlTZHAXUxFIFdyI6VHXpYkKueESCGSqgr1S3w/viewform?embedded=true" width="640" height="3057" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class V Computer Demo Examination 2024-2025',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdxu2Nlq4Ul0q395KtoPDD9GmGq6Ip6DXOOFcCp-TbOFH4hIA/viewform?embedded=true" width="640" height="3422" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class 7 Bengali',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScaWAng3Ak_cIVYeuBw38q5jeHb_g19ZfdjISedhUaZyKw9bA/viewform?embedded=true" width="640" height="2892" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class 7 Hindi',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdmRVcfjFAA31M6__ZRGbECWU9vjqHcjiWVMIddr4J1YQ4ENQ/viewform?embedded=true" width="640" height="3142" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class 6 Bengali',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeh6ntQCU1hvc7UJXUcf5GyFwQ6f24_0Duw5i6dJWV6NrMZJg/viewform?embedded=true" width="640" height="2830" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class 6 Hindi',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdFtYVSnBHQ9-8sYazgCO6Y1YGN2eiZfHkOPZdblgY1stbJ-Q/viewform?embedded=true" width="640" height="3066" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class V Hindi',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScuz64PpZlsyH6Qjw1ySXFy1NEUuzb8sUXKsFkYLG2ImkkexQ/viewform?embedded=true" width="640" height="3537" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  
  
];

const ExamCenter = () => {
  const [showButtons, setShowButtons] = useState(true);
  const [openTestIndex, setOpenTestIndex] = useState('');

  return (
    <div className='exam-center-container'>
      {showButtons && (
        <div className='tw-container tw-mx-auto tw-h-screen tw-mt-10'>
          {list
            .filter(({ active }) => active)
            .map((item) => (
              <button
                key={item.title}
                className='hover:tw-ease-out tw-duration-300 tw-w-full tw-py-3 hover:tw-text-[#730e1c] tw-font-bold tw-rounded-lg tw-text-white tw-mb-5 tw-border-[#730e1c] tw-bg-[#730e1c] hover:tw-bg-white tw-border-2'
                onClick={() => {
                  setOpenTestIndex(item.title);
                  setShowButtons(false);
                }}>
                {item.title}
              </button>
            ))}
        </div>
      )}

      <div className='exam-questions'>
        {(() => {
          const activeItem = list.find((list) => list.active && list.title === openTestIndex);

          if (activeItem) {
            return (
              <div
                className='toggle-section'
                dangerouslySetInnerHTML={{
                  __html: activeItem.iframe,
                }}></div>
            );
          }

          return null;
        })()}
      </div>
    </div>
  );
};

export default ExamCenter;
